import React from 'react';
import { bool, func } from 'prop-types';
import styled from "@emotion/styled"

const Wrapper = styled.div`
    position: absolute;
    top: 2.25rem;
    left: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;

    span {
    width: 2.5rem;
    height: 0.25rem;
    margin: 0.25rem;
    background: black;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    z-index: 10;

        :first-child {
            transform: ${({ open }) => open ? 'rotate(40deg)' : 'rotate(0)'};
        }

        :nth-child(2) {
            opacity: ${({ open }) => open ? '0' : '1'};
            transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
        }

        :nth-child(3) {
            transform: ${({ open }) => open ? 'rotate(-40deg)' : 'rotate(0)'};
        }

    }

    p {
        margin: 0;
        margin-top: 0.2rem;
        font-family: Adobe Caslon Pro, serif;
        font-weight: 600;
        z-index: 1;
        text-transform: uppercase;
    }

    @media (min-width: 1024px) {
        display: none;
    }
`;

const Burger = ({ open, setOpen, ...props }) => {

    const isExpanded = open ? true : false;

    return (
      <Wrapper aria-label="Toggle menu" aria-expanded={isExpanded} open={open} onClick={() => setOpen(!open)} {...props}>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <p>Menu</p>
      </Wrapper>
    );
}

Burger.propTypes = {
    open: bool.isRequired,
    setOpen: func.isRequired,
};

export default Burger;