import React from 'react';
import { bool } from 'prop-types';
import styled from "@emotion/styled"

// https://codepen.io/PaulVanO/pen/XJYGNQ (Look at pen)

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ededed;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  height: 100vh;
  width: 105vw;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: -1rem;
  transition: transform 0.4s ease-in-out;
  z-index: 9;
  text-align: center;

  li {
    margin: 2.25rem;

    @media (min-width: 1024px) {
      margin: 4rem;
    }
  }

  a {
    font-size: 1.25rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.25rem;
    border-bottom: 1px solid #7798ab;
    text-decoration: none;
    transition: color 0.3s linear;
    &:hover {
      color: #7798ab;
    }

    @media (min-width: 768px) {
      font-size: 2rem;
    }

    @media (min-width: 1024px) {
      display: none;
    }
  }
`;

const Menu = ({ open, ...props }) => {

    const isHidden = open ? true : false;
    const tabIndex = isHidden ? 0 : -1;

    return (
        <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
          <ul>
          <li><a href="/" tabIndex={tabIndex}>Home</a></li>
          <li><a href="/about" tabIndex={tabIndex}>About</a></li>
          <li><a href="/books" tabIndex={tabIndex}>Books</a></li>
          <li><a href="/movies" tabIndex={tabIndex}>Movies</a></li>
          <li><a href="/blog" tabIndex={tabIndex}>Blog</a></li>
          <li><a href="/contact" tabIndex={tabIndex}>Contact</a></li>
          <li><a href="/insider" tabIndex={tabIndex}>Insider</a></li>
          </ul>
        </StyledMenu>
    )
}

Menu.propTypes = {
    open: bool.isRequired,
}

export default Menu;