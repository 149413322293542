import React, { useState } from 'react';
import Burger from "./burger"
import Menu from "./menu"
import styled from "@emotion/styled"
import Logo from "./robert-whitlow-logo-full-name.png"
import { Link } from "gatsby"

const NavbarContainer = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: #ededed;
  border-bottom: 2px solid black;
`

const NavWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1024px) {
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
  }
`

const NavLogoImg = styled.img`
  width: auto;
  max-height: 70px;
  margin: 0 auto;
  margin: 1rem 0;
`

const NavLinks = styled.ul`
  margin: 1rem;
  font-size: 1.4rem;
  font-weight: bold;

  & a{
    margin: 0 2rem;
    transition: color 0.3s ease;

    &:hover {
      color: #7798ab;
      transition: color 0.3s ease;
    }
  }

@media (max-width: 1023px) {
  display: none;
}

`

function Navbar() {
    const [open, setOpen] = useState(false);
    return (
        <div>
          <NavbarContainer>
            <Burger open={open} setOpen={setOpen} />
            <Menu open={open} setOpen={setOpen} />
            <NavWrap>
              <a href="/">
                <NavLogoImg src={Logo} alt='Robert Whitlow Logo'/>
              </a>
              <NavLinks>
                <Link to="/about/" activeStyle={{ textDecoration: "underline solid #7798ab" }}>About</Link>
                <Link to="/books/" activeStyle={{ textDecoration: "underline solid #7798ab" }}>Books</Link>
                <Link to="/movies/" activeStyle={{ textDecoration: "underline solid #7798ab" }}>Movies</Link>
                <Link to="/blog/" activeStyle={{ textDecoration: "underline solid #7798ab" }}>Blog</Link>
                <Link to="/contact/" activeStyle={{ textDecoration: "underline solid #7798ab" }}>Contact</Link>
                <Link to="/insider/" activeStyle={{ textDecoration: "underline solid black" }} style={{backgroundColor: '#7798ab', color: '#ededed', padding: '0.75rem 1rem 0.25rem 1rem'}}>Insider</Link>
              </NavLinks>
            </NavWrap> 
          </NavbarContainer>
        </div>
    )
}

export default Navbar