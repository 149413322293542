import React, { Component } from "react"
import PropTypes from "prop-types"
import NavBar from "./navbar/navbar"
import "./layout.css"
import styled from "@emotion/styled"
import WhitlowLogo from "../components/navbar/robert-whitlow-logo-full-name.png"
import { FaFacebook, FaGoodreads, FaTwitter } from "react-icons/fa";
import { IconContext } from "react-icons";


const FooterLogo = styled.img`
  width: 120px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`
const FooterNav = styled.div`
  margin: 1.5rem 0;
  font-size: 0.75rem;
  font-weight: bold;

  @media (min-width: 768px) {
    font-size: 1.1rem;
  }
`

const NavLink = styled.a`
  margin: 0.75rem;
  cursor: pointer;
  transition: color 0.3s ease;
  
  &:hover {
    color: #7798ab;
    transition: color 0.3s ease;
  }
  
`

const SocialMediaContainer = styled.div`
  margin: 2rem 0;

  & a {
    margin: 1rem;
    transition: color 0.3s ease;

    &:hover {
      color: #7798ab;
      transition: color 0.3s ease;
    }
  }
`

class Layout extends Component {

  render () {
    const { children } = this.props;
      return (
      <div>
        <NavBar />
        <main>{children}</main>
        <footer>
          <FooterLogo src={WhitlowLogo} alt="Robert Whitlow Logo" />
          <FooterNav>
            <NavLink href="/about/">About</NavLink>
            <NavLink href="/books/">Books</NavLink>
            <NavLink href="/movies/">Movies</NavLink>
            <br />
            <br />
            <NavLink href="/blog/">Blog</NavLink>
            <NavLink href="/contact/">Contact</NavLink>
            <NavLink href="/insider/">Insider</NavLink>
          </FooterNav>
          <IconContext.Provider value={{ className: "social-icons" }}>
            <SocialMediaContainer>
              <a href="https://www.facebook.com/robertwhitlowbooks/" target="_blank" rel="noopener noreferrer">
                <FaFacebook />
              </a>
              <a href="https://www.goodreads.com/author/show/125786.Robert_Whitlow" target="_blank" rel="noopener noreferrer">
                <FaGoodreads />
              </a>
              <a href="https://twitter.com/whitlowwriter" target="_blank" rel="noopener noreferrer">
                <FaTwitter />
              </a>
            </SocialMediaContainer>
          </IconContext.Provider>
          <p>© {new Date().getFullYear()} | Providence House</p>
        </footer>
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
